/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface AIRequest
 */
export interface AIRequest {
    /**
     * 
     * @type {number}
     * @memberof AIRequest
     */
    child_age: number;
    /**
     * 
     * @type {string}
     * @memberof AIRequest
     */
    child_name: string;
    /**
     * 
     * @type {string}
     * @memberof AIRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof AIRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof AIRequest
     */
    deadline: string;
}
/**
 * 
 * @export
 * @interface AIResponse
 */
export interface AIResponse {
    /**
     * 
     * @type {string}
     * @memberof AIResponse
     */
    generated_description: string;
}
/**
 * 
 * @export
 * @interface BalanceUpdate
 */
export interface BalanceUpdate {
    /**
     * 
     * @type {number}
     * @memberof BalanceUpdate
     */
    balance: number;
}
/**
 * 
 * @export
 * @interface BaseShopItem
 */
export interface BaseShopItem {
    /**
     * 
     * @type {string}
     * @memberof BaseShopItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShopItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof BaseShopItem
     */
    cost: number;
}
/**
 * 
 * @export
 * @interface BuyingResponse
 */
export interface BuyingResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BuyingResponse
     */
    status: boolean;
}
/**
 * 
 * @export
 * @interface Child
 */
export interface Child {
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    birthdate: string;
    /**
     * 
     * @type {Sex}
     * @memberof Child
     */
    sex: Sex;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    avatar_link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    task_count: number;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    parent: string;
}


/**
 * 
 * @export
 * @interface ChildCreate
 */
export interface ChildCreate {
    /**
     * 
     * @type {string}
     * @memberof ChildCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChildCreate
     */
    birthdate: string;
    /**
     * 
     * @type {Sex}
     * @memberof ChildCreate
     */
    sex: Sex;
    /**
     * 
     * @type {string}
     * @memberof ChildCreate
     */
    avatar_link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildCreate
     */
    parent?: string | null;
}


/**
 * 
 * @export
 * @interface DeleteResponse
 */
export interface DeleteResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteResponse
     */
    deleted: number;
}
/**
 * 
 * @export
 * @interface DeleteUserResponse
 */
export interface DeleteUserResponse {
    /**
     * 
     * @type {string}
     * @memberof DeleteUserResponse
     */
    deleted: string;
}
/**
 * 
 * @export
 * @interface ErrorResponseModel
 */
export interface ErrorResponseModel {
    /**
     * 
     * @type {Signs}
     * @memberof ErrorResponseModel
     */
    detail: Signs;
}


/**
 * 
 * @export
 * @interface PasswordUpdate
 */
export interface PasswordUpdate {
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    old_password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    new_password: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordUpdate
     */
    password_confirmation: string;
}
/**
 * 
 * @export
 * @interface Purchase
 */
export interface Purchase {
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Purchase
     */
    shop_item: number;
    /**
     * 
     * @type {string}
     * @memberof Purchase
     */
    child: string;
    /**
     * 
     * @type {boolean}
     * @memberof Purchase
     */
    accepted: boolean;
}
/**
 * 
 * @export
 * @interface RefreshToken
 */
export interface RefreshToken {
    /**
     * 
     * @type {string}
     * @memberof RefreshToken
     */
    refresh_token: string;
}
/**
 * 
 * @export
 * @interface ResponseData
 */
export interface ResponseData {
    /**
     * 
     * @type {object}
     * @memberof ResponseData
     */
    data: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseData
     */
    error: boolean;
}

/**
 * 
 * @export
 */
export const Sex = {
    M: 'M',
    F: 'F'
} as const;
export type Sex = typeof Sex[keyof typeof Sex];

/**
 * 
 * @export
 * @interface ShopItem
 */
export interface ShopItem {
    /**
     * 
     * @type {string}
     * @memberof ShopItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ShopItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ShopItem
     */
    cost: number;
    /**
     * 
     * @type {number}
     * @memberof ShopItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShopItem
     */
    parent: string;
}
/**
 * 
 * @export
 * @interface ShopItemCreate
 */
export interface ShopItemCreate {
    /**
     * 
     * @type {string}
     * @memberof ShopItemCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ShopItemCreate
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ShopItemCreate
     */
    cost: number;
    /**
     * 
     * @type {string}
     * @memberof ShopItemCreate
     */
    parent?: string | null;
}

/**
 * 1 - You don't have permissions for this operations
 * 2 - Record not found
 * 3 -Record with this params already exists
 * 4 - Invalid token
 * 5 - User is disabled
 * 6 - Password validation error
 * 7 - Parrent missmatch
 * 8 - Cannot create user with this role! Use POST /api/v1/clild/
 * 9 - Invalid username or password
 * 10 - Not enough points on balance
 * @export
 */
export const Signs = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10
} as const;
export type Signs = typeof Signs[keyof typeof Signs];

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    status?: TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    child?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    deadline: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    cost: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    parent: string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    id: number;
}


/**
 * 
 * @export
 * @interface TaskCreate
 */
export interface TaskCreate {
    /**
     * 
     * @type {string}
     * @memberof TaskCreate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskCreate
     */
    description: string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof TaskCreate
     */
    status?: TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof TaskCreate
     */
    child?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaskCreate
     */
    deadline: string;
    /**
     * 
     * @type {number}
     * @memberof TaskCreate
     */
    cost: number;
    /**
     * 
     * @type {string}
     * @memberof TaskCreate
     */
    parent?: string | null;
}



/**
 * 
 * @export
 */
export const TaskStatus = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    ON_REVIEW: 'ON_REVIEW',
    COMPLETED: 'COMPLETED'
} as const;
export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];

/**
 * 
 * @export
 * @interface TokenPair
 */
export interface TokenPair {
    /**
     * 
     * @type {string}
     * @memberof TokenPair
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenPair
     */
    refresh_token: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    role: UserRole;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    balance: number;
}


/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserCreate
     */
    role?: UserRole;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password_confirmation: string;
}



/**
 * 
 * @export
 */
export const UserRole = {
    ADMIN: 'ADMIN',
    PARENT: 'PARENT',
    CHILD: 'CHILD'
} as const;
export type UserRole = typeof UserRole[keyof typeof UserRole];

/**
 * 
 * @export
 * @interface ValidationErrorDetail
 */
export interface ValidationErrorDetail {
    /**
     * 
     * @type {Array<ValidationErrorDetailLocInner>}
     * @memberof ValidationErrorDetail
     */
    loc: Array<ValidationErrorDetailLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorDetail
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorDetail
     */
    type: ValidationErrorDetailTypeEnum;
    /**
     * 
     * @type {{ [key: string]: ValidationErrorDetailInputValue; }}
     * @memberof ValidationErrorDetail
     */
    input: { [key: string]: ValidationErrorDetailInputValue; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorDetail
     */
    url: string;
}


/**
 * @export
 */
export const ValidationErrorDetailTypeEnum = {
    no_such_attribute: 'no_such_attribute',
    json_invalid: 'json_invalid',
    json_type: 'json_type',
    recursion_loop: 'recursion_loop',
    missing: 'missing',
    frozen_field: 'frozen_field',
    frozen_instance: 'frozen_instance',
    extra_forbidden: 'extra_forbidden',
    invalid_key: 'invalid_key',
    get_attribute_error: 'get_attribute_error',
    model_type: 'model_type',
    model_attributes_type: 'model_attributes_type',
    dataclass_type: 'dataclass_type',
    dataclass_exact_type: 'dataclass_exact_type',
    none_required: 'none_required',
    greater_than: 'greater_than',
    greater_than_equal: 'greater_than_equal',
    less_than: 'less_than',
    less_than_equal: 'less_than_equal',
    multiple_of: 'multiple_of',
    finite_number: 'finite_number',
    too_short: 'too_short',
    too_long: 'too_long',
    iterable_type: 'iterable_type',
    iteration_error: 'iteration_error',
    string_type: 'string_type',
    string_sub_type: 'string_sub_type',
    string_unicode: 'string_unicode',
    string_too_short: 'string_too_short',
    string_too_long: 'string_too_long',
    string_pattern_mismatch: 'string_pattern_mismatch',
    enum: 'enum',
    dict_type: 'dict_type',
    mapping_type: 'mapping_type',
    list_type: 'list_type',
    tuple_type: 'tuple_type',
    set_type: 'set_type',
    bool_type: 'bool_type',
    bool_parsing: 'bool_parsing',
    int_type: 'int_type',
    int_parsing: 'int_parsing',
    int_parsing_size: 'int_parsing_size',
    int_from_float: 'int_from_float',
    float_type: 'float_type',
    float_parsing: 'float_parsing',
    bytes_type: 'bytes_type',
    bytes_too_short: 'bytes_too_short',
    bytes_too_long: 'bytes_too_long',
    value_error: 'value_error',
    assertion_error: 'assertion_error',
    literal_error: 'literal_error',
    date_type: 'date_type',
    date_parsing: 'date_parsing',
    date_from_datetime_parsing: 'date_from_datetime_parsing',
    date_from_datetime_inexact: 'date_from_datetime_inexact',
    date_past: 'date_past',
    date_future: 'date_future',
    time_type: 'time_type',
    time_parsing: 'time_parsing',
    datetime_type: 'datetime_type',
    datetime_parsing: 'datetime_parsing',
    datetime_object_invalid: 'datetime_object_invalid',
    datetime_from_date_parsing: 'datetime_from_date_parsing',
    datetime_past: 'datetime_past',
    datetime_future: 'datetime_future',
    timezone_naive: 'timezone_naive',
    timezone_aware: 'timezone_aware',
    timezone_offset: 'timezone_offset',
    time_delta_type: 'time_delta_type',
    time_delta_parsing: 'time_delta_parsing',
    frozen_set_type: 'frozen_set_type',
    is_instance_of: 'is_instance_of',
    is_subclass_of: 'is_subclass_of',
    callable_type: 'callable_type',
    union_tag_invalid: 'union_tag_invalid',
    union_tag_not_found: 'union_tag_not_found',
    arguments_type: 'arguments_type',
    missing_argument: 'missing_argument',
    unexpected_keyword_argument: 'unexpected_keyword_argument',
    missing_keyword_only_argument: 'missing_keyword_only_argument',
    unexpected_positional_argument: 'unexpected_positional_argument',
    missing_positional_only_argument: 'missing_positional_only_argument',
    multiple_argument_values: 'multiple_argument_values',
    url_type: 'url_type',
    url_parsing: 'url_parsing',
    url_syntax_violation: 'url_syntax_violation',
    url_too_long: 'url_too_long',
    url_scheme: 'url_scheme',
    uuid_type: 'uuid_type',
    uuid_parsing: 'uuid_parsing',
    uuid_version: 'uuid_version',
    decimal_type: 'decimal_type',
    decimal_parsing: 'decimal_parsing',
    decimal_max_digits: 'decimal_max_digits',
    decimal_max_places: 'decimal_max_places',
    decimal_whole_digits: 'decimal_whole_digits'
} as const;
export type ValidationErrorDetailTypeEnum = typeof ValidationErrorDetailTypeEnum[keyof typeof ValidationErrorDetailTypeEnum];

/**
 * 
 * @export
 * @interface ValidationErrorDetailInputValue
 */
export interface ValidationErrorDetailInputValue {
}
/**
 * 
 * @export
 * @interface ValidationErrorDetailLocInner
 */
export interface ValidationErrorDetailLocInner {
}
/**
 * 
 * @export
 * @interface ValidationErrorModel
 */
export interface ValidationErrorModel {
    /**
     * 
     * @type {Array<ValidationErrorDetail>}
     * @memberof ValidationErrorModel
     */
    detail: Array<ValidationErrorDetail>;
}
