"use client";

import {
  Input,
  Label,
  TextField,
  type TextFieldProps,
} from "react-aria-components";
import { useIntl } from "react-intl";

import { TextFieldSupportingText } from "../../../base-ui";

export const PasswordField = (props: TextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      className="text-field text-field_filled"
      type="password"
      {...props}
    >
      <div className="text-field__container">
        <Label className="label">
          {intl.formatMessage({ defaultMessage: "Пароль", id: "PuZv4f" })}
        </Label>
        <Input className="input w-full" />
      </div>
      <TextFieldSupportingText />
    </TextField>
  );
};
