"use client";

import { captureMessage, setExtras } from "@sentry/nextjs";
import { clsx } from "clsx";
import { useContext, useMemo } from "react";
import {
  FieldError,
  FieldErrorContext,
  Text,
  type TextProps,
} from "react-aria-components";
import { useIntl } from "react-intl";

export const TextFieldSupportingText = (props: TextProps) => {
  const { children: childrenProp, className, ...rest } = props;

  const intl = useIntl();

  const { isInvalid, validationDetails, validationErrors } =
    useContext(FieldErrorContext);

  const children = useMemo(() => {
    if (!isInvalid) {
      return;
    }

    switch (true) {
      case validationDetails.customError: {
        return;
      }

      case validationDetails.valueMissing: {
        return intl.formatMessage({
          defaultMessage: "Заполните это поле",
          id: "CSpKmK",
        });
      }

      default: {
        if (Object.values(validationDetails).includes(true)) {
          setExtras({ validationDetails });
          captureMessage("Localized message is not provided");
        }
        break;
      }
    }

    return validationErrors.at(0);
  }, [intl, isInvalid, validationDetails, validationErrors]);

  if (!isInvalid) {
    return (
      <Text
        className={clsx("text-field__supporting-text", className)}
        slot="description"
      >
        {childrenProp}
      </Text>
    );
  }

  return (
    <FieldError
      className={clsx("text-field__supporting-text field-error", className)}
      {...rest}
    >
      {children}
    </FieldError>
  );
};
