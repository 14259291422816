"use client";

import { type PropsWithChildren } from "react";
import { I18nProvider as RacI18nProvider } from "react-aria-components";
import { IntlProvider } from "react-intl";

import { locale } from "../../locale";
import { onError } from "../../onError";
import { timeZone } from "../../timeZone";

export const I18nProvider = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <IntlProvider locale={locale} onError={onError} timeZone={timeZone}>
      <RacI18nProvider locale={locale}>{children}</RacI18nProvider>
    </IntlProvider>
  );
};
