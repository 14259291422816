import { type ToastOptions } from "@react-stately/toast";

import {
  notificationQueue,
  type QueuedNotification,
} from "./notificationQueue";

export const showNotification = (
  content: QueuedNotification,
  options?: ToastOptions,
) => {
  for (const toast of notificationQueue.visibleToasts) {
    notificationQueue.close(toast.key);
  }

  notificationQueue.add(content, {
    timeout: 6000,
    ...options,
  });
};
