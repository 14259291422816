"use client";

import { useIntl } from "react-intl";

import {
  type ValidationErrorDetail,
  ValidationErrorDetailTypeEnum,
} from "../backend";

type ResponseValidationErrors<T extends object> = Partial<
  Record<keyof T, ValidationErrorDetail[]>
>;

type FormValidationErrors<T extends object> = Partial<
  Record<keyof T, string[]>
>;

export const useValidationErrors = <T extends object>(
  responseErrors?: ResponseValidationErrors<T>,
): FormValidationErrors<T> | undefined => {
  const intl = useIntl();

  if (!responseErrors) {
    return;
  }

  const formErrors: FormValidationErrors<T> = {};

  for (const key in responseErrors) {
    const errors = responseErrors[key];

    formErrors[key] = errors?.map((error) => {
      switch (error.type) {
        case ValidationErrorDetailTypeEnum.missing: {
          return intl.formatMessage({
            defaultMessage: "Заполните это поле",
            id: "CSpKmK",
          });
        }

        default: {
          return error.msg;
        }
      }
    });
  }

  return formErrors;
};
