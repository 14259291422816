"use client";

import { clsx } from "clsx";
import { useSearchParams } from "next/navigation";
import { useActionState } from "react";
import { Button, Form, type FormProps } from "react-aria-components";
import { useIntl } from "react-intl";

import { type LoginApiV1AuthLoginPostRequest } from "../../../backend";
import { useValidationErrors } from "../../../base-ui";
import { getResponseErrorMessage } from "../../../base-ui/getResponseErrorMessage";
import { showNotification } from "../../../notification";

import { EmailField } from "./EmailField";
import { PasswordField } from "./PasswordField";
import { signInAction } from "./signInAction";

import styles from "./SignInForm.module.css";

export const SignInForm = (props: FormProps) => {
  const { className, ...rest } = props;

  const searchParams = useSearchParams();

  const intl = useIntl();

  const [state, action] = useActionState(
    async (currentState: unknown, formData: FormData) => {
      const nextState = await signInAction(
        formData,
        searchParams.get("redirect"),
      );

      if (nextState.responseError !== undefined) {
        showNotification({
          text: getResponseErrorMessage(nextState.responseError, intl),
        });
      }

      return nextState;
    },
    {
      values: {},
    },
  );

  return (
    <Form
      action={action}
      className={clsx(styles.signInForm, className)}
      validationErrors={useValidationErrors<LoginApiV1AuthLoginPostRequest>(
        state?.validationErrors,
      )}
      {...rest}
    >
      <EmailField
        defaultValue={state.values.username}
        isRequired
        key={`username-${state.values.username}`}
        name="username"
      />
      <PasswordField
        defaultValue={state.values.password}
        isRequired
        key={`password-${state.values.password}`}
        name="password"
      />
      <Button className="button button_filled" type="submit">
        {intl.formatMessage({ defaultMessage: "Войти", id: "oU4rxk" })}
      </Button>
    </Form>
  );
};
