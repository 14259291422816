import { ToastQueue } from "@react-stately/toast";
import { type ReactNode } from "react";

export type QueuedNotification = {
  action?: ReactNode;
  closable?: boolean;
  text: ReactNode;
};

export const notificationQueue = new ToastQueue<QueuedNotification>({
  hasExitAnimation: true,
  maxVisibleToasts: 1,
});
