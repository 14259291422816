import { IntlErrorCode, type OnErrorFn } from "@formatjs/intl";
import { captureException } from "@sentry/nextjs";

export const onError: OnErrorFn = (error) => {
  if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
    return;
  }

  captureException(error);
};
